import axios from "axios"

const local = false
const url = local ? "http://localhost:1234/api" : "https://textarea.benjamin-widawski.com/api"

const api = {
    check: (callback) => {
        axios.get(url)
            .then(callback)
    },
    getText: async () => {
        return await axios.get(url + "/text")
    },
    updateText: (newText) => {
        return new Promise((resolve, _) => {
            axios.post(url + "/text/", {
                value: newText
            })
                .then(result => resolve(result.data))
        })
    },
}

export default api